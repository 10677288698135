<template>
    <div class="max_div">
        <div class="top_div">
            <div class="block_div">
                <el-row :gutter="20">
                    <el-col :span="4">
                        <div class="block">
                            <div class="data_div">
                                镜方总数
                            </div>
                            <div class="data_div">
                                {{report.num}}
                            </div>
                        </div>
                    </el-col>

                    <el-col :span="4">
                        <div class="block">
                            <div class="data_div">
                                未审核数量
                            </div>
                            <div class="data_div">
                                {{report.num1}}
                            </div>
                        </div>
                    </el-col>

                    <el-col :span="4">
                        <div class="block">
                            <div class="data_div">
                                已审核数量
                            </div>
                            <div class="data_div">
                                {{report.num2}}
                            </div>
                        </div>
                    </el-col>

                    <el-col :span="4">
                        <div class="block">
                            <div class="data_div">
                                服务商
                            </div>
                            <div class="data_div">
                                {{report.num3}}
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>

            <div class="bottom_div">
                <div class="left_div">
                     <el-input :size="tabsize" v-model="inputData" placeholder="手机号，姓名，SN号"></el-input>
                     <el-button :size="tabsize" @click="getTableData" type="success" icon="el-icon-search">搜索</el-button>
                </div>

                <!-- <div class="right_div">
                    <el-button :size="tabsize" type="primary">导出</el-button>
                </div> -->
            </div>
        </div>

        <div class="center_div">

            <el-table :data="tableData" ref="tableRef" :size="tabsize" border stripe style="width:100%;overflow:auto">

                    <el-table-column label="序号" align='center'>
                        <template slot-scope="scope">
                            <span>{{ scope.row.id }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="孩子名称" align='center'>
                        <template slot-scope="scope">
                            <span>{{ scope.row.kname }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="手机号" align='center'>
                        <template slot-scope="scope">
                            <span>{{ scope.row.phone }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="城市" align='center'>
                        <template slot-scope="scope">
                            <span>{{ scope.row.city+scope.row.province+scope.row.area }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column  style="color:red"  label="SN码" align='center'>
                        <template slot-scope="scope">
                            <span>{{ scope.row.sn }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="提交时间" align='center'>
                        <template slot-scope="scope">    
                            <span>{{ scope.row.ctime  | tick2ymd}}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="操作" align='center'>
                        <template slot-scope="scope">
                            <div style="color:red;cursor:pointer"  @click="downData(scope.row.id)">
                                {{ scope.row.status | visionType}}
                            </div>
                        </template>
                    </el-table-column>

            </el-table>
        </div>

        <div class="footer_div">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
                    :page-sizes="[10, 20, 30, 40]" :page-size="pageSize"  layout="total, sizes, prev, pager, next, jumper"
                    :total="tableData.length">
            </el-pagination>
        </div>

    </div>
</template>

<script>
    export default {
        mounted(){
            this.getNum()
            this.getTableData()
        },
        data(){
            return{
                titleData:[
                    {
                        name:'未读',
                        num:999
                    }
                ],
                inputData:'',
                tableData:[],
                currentPage: 1, //默认显示第一页
                pageSize: 10, //默认每页显示10条
                totalNum: '', //总页数
                order:'desc',
                report:[]
            }
        },
        // 监听尺寸按钮变化 改变表格大小
      computed: {
          tabsize() {
                this.$setTableHeightMax()
                return this.$store.state.tabsize
          }
      },
      methods:{
            downData(item){
                this.$router.push({
                    path: "/visionMirrorData",
                    query: {kid:item},
                });
            },
            getNum(){
                this.$api.vision.getCount({
                    
                }).then(res => {
                    this.report = res.obj
                }).catch((error) => {
                    console.log(error);
                })
            },
            getTableData(){
                this.$api.vision.getList({
                    'jPage.pageNum': this.currentPage,
                    'jPage.pageSize': this.pageSize,
                    'jPage.sort': this.sort,
                    'jPage.order': this.order,
                    search:this.inputData
                }).then(res => {
                    console.log(res);
                    this.tableData = res.obj.list
                
                }).catch((error) => {
                    console.log(error);
                })
            },
            // 分页大小
            handleSizeChange(val) {
                this.pageSize = val
                this.currentPage = 1 // 选择分页大小后跳转第一页
                this.getTableData()
            },
            // 第几页
            handleCurrentChange(val) {
                this.currentPage = val
                this.getTableData()
            },
      },
      filters:{
        visionType:function(type){
            if(type == 1){
                return '待审核'
            }else if(type==2){
                return '已审核'
            }
            return '未知状态'
        }
      }
      
    }
</script>

<style scoped>
    .max_div{
        position: relative;
        width: 98%;
        height: 98%;
        margin: 1%;
    }
    .block_div{
        width: 100%;
        height: 8vh;
    }
    .block{
        width: 100%;
        height: 100%;
        background-color: #fff;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .el-row{
        width: 100%;
        height: 100%;
    }

    .el-col{
        height: 100%;
    }

    .center_div{
        margin-top: 1%;
    }
    .title_data{
        width: 100%;
        height: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .text{
        white-space: nowrap;
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .bottom_div{
        margin-top: 1%;
        display: flex;
        justify-content: space-between;

    }
    .left_div{
        display: flex;
    }
    .right_div{
        margin-right: 2px;
    }
    /deep/ .el-button{
        margin-left: 3%;
    }
    
    /deep/ .el-table{
        height: 100%;
    }
     .el-pagination {
        padding: 0% 1% 0% 0%;
        float: right;
        margin-top: 10px;
    }
    
</style>